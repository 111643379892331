import React, { useContext } from "react"
import classnames from "classnames"

/* Import Global Context */
import ColorContext from "~context/color"

/* Import Local Assets */
import ProfileImagePlaceholderSrc from "./assets/placeholder.png"

/* Import Local Styles */
import "./voice-picker.css"

const VoicePicker = ({
  setVoice = () => {},
  newVoice = "Distinguished",
  voiceLabel = "Distinguished",
  profileImage = ProfileImagePlaceholderSrc,
  callback = () => {},
}) => {
  const [color] = useContext(ColorContext)
  return (
    <button
      className="voice-picker"
      onClick={() => {
        setVoice(newVoice)
        callback()
      }}
    >
      <div
        className={classnames("portrait", color.background)}
        style={{ backgroundImage: `url(${profileImage})` }}
      />
      <p className="name">{voiceLabel}</p>
    </button>
  )
}

export default VoicePicker
