import React, { useState, useEffect } from "react"
import classnames from "classnames"

/* Import Global Components */
import RightHandIcon from "~components/icon/normal/rightHand"
import VoicePicker from "~components/voicePicker"
import FadeInChildElements from "~components/animations/fadeInChildElements"
import FadeInCharactersSequentially from "~components/animations/fadeInCharactersSequentially"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Assets */
import CalmSvgSrc from "./assets/calm.svg"
import GrandmaSvgSrc from "./assets/grandma.svg"
import CowboySvgSrc from "./assets/cowboy.svg"

/* Import Local Styles */
import "./voices.css"

const VoicesSlide = ({ active, setVoice }) => {
  const [slideVisited, setSlideVisited] = useState(false)
  const [textAnimationComplete, setTextAnimationComplete] = useState(false)

  const goToNextSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideRight()
    }
  }

  useEffect(() => {
    if (active) {
      setSlideVisited(true)
    }
  }, [active])

  return (
    <Slide title="voices">
      <div className="voices-container">
        <FadeInChildElements start={slideVisited}>
          <div>
            <div className="copy">
              <span
                className={classnames("icon-container", {
                  wiggle: slideVisited,
                })}
              >
                <RightHandIcon />
              </span>
              <FadeInCharactersSequentially
                start={setSlideVisited}
                copy={"Pick a Voice Over"}
                callback={() => {
                  setTextAnimationComplete(true)
                }}
              />
            </div>
            <FadeInChildElements
              start={textAnimationComplete}
              transitionDuration={1200}
            >
              <div className="voice-pickers">
                <VoicePicker
                  setVoice={setVoice}
                  newVoice={"cowboy"}
                  voiceLabel={"cowboy"}
                  profileImage={CowboySvgSrc}
                  callback={goToNextSlide}
                />
                <VoicePicker
                  setVoice={setVoice}
                  newVoice={"asmr"}
                  voiceLabel={"asmr"}
                  profileImage={CalmSvgSrc}
                  callback={goToNextSlide}
                />
                <VoicePicker
                  setVoice={setVoice}
                  newVoice={"auntRuthie"}
                  voiceLabel={"aunt ruthie"}
                  profileImage={GrandmaSvgSrc}
                  callback={goToNextSlide}
                />
                {/* <VoicePicker
              setVoice={setVoice}
              newVoice={"relaxed"}
              profileImage={CalmSvgSrc}
              callback={goToNextSlide}
            />
            <VoicePicker
              setVoice={setVoice}
              newVoice={"cowboy"}
              profileImage={CowboySvgSrc}
              callback={goToNextSlide}
            /> */}
              </div>
            </FadeInChildElements>
          </div>
        </FadeInChildElements>
      </div>
    </Slide>
  )
}

export default VoicesSlide
